import "./index.scss";

import { useContext } from "react";
import { Link } from "react-router-dom";

import Facebook from "../../Assets/images/icons/facebook.svg";
import Instagram from "../../Assets/images/icons/instagram.svg";
import Context from "../../Context";

const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div id="footer">
            <div className="container contato">
                <img src={resumo.imagens.contato_card} alt="Done Irene" />
                <div className="textos">
                    <h3 dangerouslySetInnerHTML={{ __html: resumo.textos.contato_card_titulo }}></h3>
                    <p dangerouslySetInnerHTML={{ __html: resumo.textos.contato_card_texto }}></p>
                </div>
                <a href={resumo.textos.contato_card_link} target="_blank" rel="noreferrer noopener">{resumo.textos.contato_card_cta}</a>
            </div>
            <div className="footer">
                <div className="container">
                    <div>
                        <div className="logos">
                            <img src={resumo.imagens.logo} alt="Porto Farma" />
                            <img src={resumo.imagens.selo_poupe} alt="Poupe já" />
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: resumo.textos.rodape_resumo }}></p>
                        <div className="socials">
                            {resumo.configuracoes.facebook.length > 5 && <a href={resumo.configuracoes.facebook} target="_blank" rel="noreferrer noopener"><img src={Facebook} alt="Facebook" /></a>}
                            {resumo.configuracoes.instagram.length > 5 && <a href={resumo.configuracoes.instagram} target="_blank" rel="noreferrer noopener"><img src={Instagram} alt="Instagram" /></a>}
                        </div>
                    </div>
                    <div>
                        <h3>Links de acesso</h3>
                        <div className="links">
                            <Link to="/empresa" title="Empresa">Empresa</Link>
                            <Link to="/farmacia" title="Farmácia">Farmácia</Link>
                            <Link to="/contato" title="Contato">Contato</Link>
                            <Link to="/trabalhe-conosco" title="Trabalhe conosco">Trabalhe conosco</Link>
                            <Link to="/acoes" title="Ações">Ações</Link>
                        </div>
                    </div>
                    <div>
                        <h3>Endereço</h3>
                        <p>{resumo.configuracoes.endereco} - {resumo.configuracoes.bairro} - {resumo.configuracoes.cidade}/{resumo.configuracoes.estado}  -  {resumo.configuracoes.cep}</p>
                        <p>{resumo.configuracoes.email}</p>
                        <p>{resumo.configuracoes.whatsapp}</p>
                    </div>
                    <div>
                        <h2 dangerouslySetInnerHTML={{ __html: resumo.textos.rodape_cta_titulo }}></h2>
                        <p dangerouslySetInnerHTML={{ __html: resumo.textos.rodape_cta_texto }}></p>
                        <a href={resumo.textos.rodape_cta_link} target="_blank" className="cotacao" rel="noreferrer noopener">{resumo.textos.rodape_cta_btn}</a>
                    </div>
                </div>
            </div>
            <p>Desenvolvido por <a href="https://fw2propaganda.com.br/" title="Desenvolvido por Gustavo Barbosa" target="_blank" rel="noreferrer noopener">FW2 Propaganda</a></p>
        </div>
    )
}

export default Footer;