import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

import Menu from "../../Assets/images/icons/menu.svg";
import Close from "../../Assets/images/icons/close.svg";
import WhatsApp from "../../Assets/images/icons/whatsapp.svg";

import Context from "../../Context";

import { numbersOnly } from "../../Utils"

const Header = () => {
    const { resumo } = useContext(Context);
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <div id="header">
            <div className="container">
                <Link to="/" title="Porto Farma"><img src={resumo.imagens.logo} alt="Porto Farma" /></Link>
                <button onClick={() => setMenuOpen(!menuOpen)} className={`${menuOpen ? "open" : ""}`}><img src={menuOpen ? Close : Menu} alt="Menu" /></button>
                <div className={`menu ${menuOpen ? "open" : ""}`}>
                    <Link to="/" title="Home">Home</Link>
                    <Link to="/empresa" title="Empresa">Empresa</Link>
                    <Link to="/farmacia" title="Farmácia">Farmácia</Link>
                    <Link to="/contato" title="Contato">Contato</Link>
                    <a href={`https://wa.me/55${numbersOnly(resumo.configuracoes.whatsapp)}`} target="_blank" rel="noreferrer noopener">Disk Entrega <img src={WhatsApp} alt="Disk Entrega" /></a>
                </div>
            </div>
        </div>
    )
}

export default Header;